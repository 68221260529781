/**
 * Created by razial on 11.03.2025.
 */

var FakeMap2dAdapter = function () {

};

FakeMap2dAdapter.prototype.load = function () {

};

FakeMap2dAdapter.prototype.save = function () {

};

FakeMap2dAdapter.prototype.remove = function () {

};

FakeMap2dAdapter.prototype.loadUnit = function () {

};

FakeMap2dAdapter.prototype.saveUnit = function () {

};

FakeMap2dAdapter.prototype.removeUnit = function () {

};

FakeMap2dAdapter.prototype.removeUnitsInFog = function () {

};

FakeMap2dAdapter.prototype.restoreKickOuts = function () {

};

FakeMap2dAdapter.prototype.checkReset = function () {

};

FakeMap2dAdapter.prototype.checkReset2 = function () {

};

FakeMap2dAdapter.prototype.isNewGame = function () {
    return true;
};